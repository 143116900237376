import { environment } from 'src/environments/environment';
import { AuthService } from './../_core/auth/auth.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotificationsService } from '../_core/notifications/notifications.service';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { User } from 'src/app/models/user';
import { Shuttle } from 'src/app/models/shuttle';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ShuttleService {
  env = environment;
  authUser: User = null;
  shuttleNames: { [id: string]: string } = {};
  // TODO: Set to false after testing
  showShuttles = true;
  debugMode = false;
  socket: any;

  constructor(
    private http: HttpClient,
    private notifications: NotificationsService,
    private authService: AuthService
  ) { }

  getAllRoutes(): Observable<any> {
    const url = this.env.apiUrl + '/routes';
    return this.http.get(url).pipe(
      map((data: any) => data),
      catchError(err => of(err))
    );
  }

  // getRouteShuttles(id: string): Observable<Shuttle[]> {
  //   const url = this.env.apiUrl + '/route/' + id + '/shuttle';
  //   return this.http
  //     .get<{
  //       leaving: { id: string; lat: number; lng: number; timeToDestination: number }[];
  //       approaching: { id: string; lat: number; lng: number; timeToDestination: number }[];
  //     }>(url)
  //     .pipe(
  //       map((resp) => {
  //         resp.leaving = resp.leaving || [];
  //         resp.approaching = resp.approaching || [];
  //         // TODO: Handle multi-route here
  //         return [
  //           ...resp.leaving.map((s) => ({ ...s, leaving: true, routeId: id })),
  //           ...resp.approaching.map((s) => ({ ...s, leaving: false, routeId: id })),
  //         ];
  //       }),
  //       catchError(err => of(err))
  //     );
  // }

  getAllShuttles(): Observable<any> {
    const url = this.env.apiUrl + '/buses';
    return this.http.get(url).pipe(
      map((data: any) => data),
      catchError(err => {
        this.notifications.handleError(err, 'shuttleService.getAllShuttles: ' + url);
        return of(err);
      })
    );
  }

  getShuttleNames(shuttleIdArray: string[]): Observable<{ [id: string]: string }> {
    const notExist = [];
    const resultMap: { [id: string]: string } = {};
    for (const id of shuttleIdArray) {
      if (!this.shuttleNames[id]) {
        notExist.push(id);
      } else {
        resultMap[id] = this.shuttleNames[id];
      }
    }
    if (notExist.length === 0) {
      return of(resultMap);
    }
    return this.http
      .post<{ [id: string]: string }>(this.env.apiUrl + '/shuttles/names', {
        shuttleIdArray,
      })
      .pipe(
        tap((m) => {
          Object.keys(m).forEach((deviceId) => {
            this.shuttleNames[deviceId] = m[deviceId];
          });
        })
      )
      .pipe(
        map((m) => {
          Object.keys(m).forEach((deviceId) => {
            resultMap[deviceId] = m[deviceId];
          });
          return resultMap;
        })
      );
  }

  // connectSocket(): Observable<any> {
  //   return new Observable((observer) => {
  //     if (!this.socket) {
  //       this.socket = io(this.env.apiUrl + '/routeShuttles', { forceNew: true, path: '/shuttleme/socket.io' });
  //     }
  //     this.socket.on('connect_error', (error) => {
  //       observer.error(error);
  //     });
  //     this.socket.on('reconnecting', (timeout) => {
  //       if (timeout === 3) {
  //         this.socket.close();
  //         this.socket = null;
  //         observer.error(`reconnecting failed`);
  //       }
  //     });
  //     this.socket.on('connect', () => {
  //       console.log(`connected`);
  //       observer.next();
  //       observer.complete();
  //     });
  //   });
  // }


  saveShuttleRoute(shuttle: Shuttle, routeId: any): Observable<any> {
    const url = this.env.apiUrl + '/shuttles';
    const authUser = this.authService.getAuthUser();
    const body = {
      id: shuttle.id,
      name: shuttle.name,
      routeId,
      busDriver: authUser
    };
    return this.http.put(url, body).pipe(
      map((data: any) => data),
      catchError(err => of(err))
    );
  }

  deleteShuttleRoute(shuttleId: any): Observable<any> {
    const url = this.env.apiUrl + '/shuttles/' + shuttleId;
    return this.http.delete(url).pipe(
      map((data: any) => data),
      catchError(err => of(err))
    );
  }

  // formatTime(shuttle: ShuttlePosition): void {
  //   // Test Data
  //   shuttle.arrived = true;
  //   shuttle.timeToDestination = 20000;

  //   let timeMs = shuttle.timeToDestination;
  //   if (!timeMs) {
  //     timeMs = 0;
  //   }
  //   const seconds = timeMs / 1000;
  //   shuttle.timeToDestSec = Math.round(seconds);
  //   const minutes = Math.floor(seconds / 60);
  //   shuttle.timeToDestMin = minutes;
  //   const arrivalMoment = moment().add(minutes, 'minute');
  //   shuttle.timeToDestHoursMinutes = arrivalMoment.format('h:mm a');
  //   shuttle.timeToDestExactTime = moment().add(minutes, 'minute').format('HH:mm:ss');
  //   shuttle.timeToDestLabel = (seconds < 30) ? 'Arriving now' : minutes + ' minute(s)';
  //   shuttle.hoursFormatted = arrivalMoment.format('h');
  //   shuttle.minutesFormatted = arrivalMoment.format('mm');
  //   shuttle.meridian = arrivalMoment.format('a').substring(0, 1);
  //   shuttle.minutesLeft = Math.round(Math.floor(shuttle.timeToDestSeconds / 60));
  //   shuttle.status = this.setShuttleStatus(shuttle);
  // }
}
