import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { TrackingService } from './services/tracking/tracking.service';
import { Keyboard } from '@capacitor/keyboard';
import { environment } from 'src/environments/environment';
import { ThemeOption } from './models/_core/theme-option';
import { Subscription } from 'rxjs';
import { AuthService } from './services/_core/auth/auth.service';
import { UserDeviceService } from './services/_core/user-device/user-device.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { Platform } from '@ionic/angular';
import { BhAnalyticsService } from './services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from './services/_core/notifications/notifications.service';
import { VerlockerService } from './services/_core/verlocker/verlocker.service';
import { User } from './models/user';
import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { NetworkService } from './services/_core/network/network.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
/**
 * ID: app-component
 * Name: App-Component
 * Description: Root App Component that handles root-level app-wide logic.
 * Version: 2
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2022-05-26 - MW - v2: Integraeted dark mode preference
 */
export class AppComponent implements OnInit {
  env = environment;
  prefersDark = false;
  theme: ThemeOption = 'M';
  subs: Subscription[] = [];
  updateInterval = null;
  displayingVerlocker = false;
  loadingSub: Subscription = null;
  isLoading = false;
  loadingMessage = '';
  authUserSub: Subscription = null;
  authUser: User = null;
  isOnline = false;
  isConnected = false;
  netInterval = null;
  isCheckingNetwork = false;

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private notifications: NotificationsService,
    private analytics: BhAnalyticsService,
    private verlockerService: VerlockerService,
    private deviceService: UserDeviceService,
    private authService: AuthService,
    private trackingService: TrackingService,
    private backgroundMode: BackgroundMode,
    private splash: SplashScreen,
    private networkService: NetworkService,
    private insomnia: Insomnia
  ) {
    this.initializeApp();
    this.subscribeToLoader();
    this.checkVersion();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.deviceService.loadDeviceProperties();
  }
  async initializeApp() {
    // await this.storage.create();
    this.platform.ready().then(() => {
      this.analytics.initAnalytics();
      this.initNativeFeatures();
      this.authUserSub = this.authService.authUser.subscribe(usr => {
        if (!this.authUser && usr && usr.userId && this.trackingService.loadStatus.getValue() !== 'loaded') {
          this.trackingService.init();
          this.authUser = usr;
        }
      });
    });
  }

  initNativeFeatures() {
    if (this.platform.is('mobile')) {
      this.backgroundMode.enable();
      // this.insomnia.keepAwake();
      Keyboard.setAccessoryBarVisible({ isVisible: true });
      this.statusBar.hide();
      this.dismissSplash();
    }
  }

  async ngOnInit() {
    this.listenForThemePreference();
    this.subscribeToUserDevice();
    this.subscribeToUserState();
    this.subscribeToNetwork();
  }

  dismissSplash() {
    setTimeout(() => {
      this.splash.hide();
    }, 2000);
  }

  subscribeToNetwork() {
    this.subs.push(
      this.networkService.isOnline.subscribe(val => {
        console.log('subscribeToNetwork: Network changed: ', val);
        this.isOnline = val;
      }),

      this.networkService.isConnected.subscribe(val => {
        console.log('subscribeToNetwork: Network changed: ', val);
        this.isConnected = val;
      })
    );
  }

  async reconnect() {
    try {
      const res = await this.networkService.checkApiHealth().toPromise();
      console.log('reconnect: health', res);
      if (res && res.name && res.version) {
        console.log('reconnect: Connection successful');
        this.networkService.isOnline.next(true);
        this.networkService.isConnected.next(true);
      }
    } catch (err) {
      console.log('Error: Attempted to connect: ', err);
    }
  }

  dismissNetInterval() {
    if (this.netInterval) {
      clearInterval(this.netInterval);
      this.netInterval = null;
    }
  }

  listenForThemePreference() {
    window.matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (mediaQuery) => {
        const device = this.deviceService.deviceSubject.getValue();
        device.prefersDark = mediaQuery.matches;
        this.deviceService.deviceSubject.next(device);
      });
  }

  subscribeToUserState() {
    this.subs.push(
      this.authService.userStateSubject.subscribe(us => {
        this.theme = us.theme;
        this.setTheme();
      })
    );
  }

  subscribeToUserDevice() {
    this.subs.push(
      this.deviceService.deviceSubject.subscribe(d => {
        this.prefersDark = d.prefersDark;
        this.setTheme();
      })
    );
  }

  setTheme() {
    // Check if theme is user-defined
    let currentTheme: ThemeOption;
    if (this.env.theme === 'user') {
      if (this.theme !== 'M') {
        switch (this.theme) {
          case 'L':
            document.body.classList.remove('dark');
            currentTheme = 'L';
            break;
          case 'D':
            document.body.classList.add('dark');
            currentTheme = 'D';
            break;
        }
      } else if (this.prefersDark) {
        document.body.classList.add('dark');
        currentTheme = 'D';
      } else {
        document.body.classList.remove('dark');
        currentTheme = 'L';
      }
    } else {
      // Theme is defined by environment
      if (this.env.theme === 'dark') {
        document.body.classList.add('dark');
        currentTheme = 'D';
      } else {
        document.body.classList.remove('dark');
        currentTheme = 'L';
      }
    }
    this.authService.setTheme(currentTheme);
  }

  subscribeToLoader() {
    this.loadingSub = this.notifications.isLoadingBehaviorSubject.subscribe(val => {
      this.isLoading = val.isLoading;
      this.loadingMessage = val.message;
    });
  }

  checkVersion() {
    if (!this.updateInterval) {
      this.verlockerService.checkVersion().toPromise();
      this.updateInterval = setInterval(() => {
        if (!this.verlockerService.displayingVerlocker) {
          this.verlockerService.checkVersion().toPromise();
        } else {
        }
      }, 120000);
    }
  }

}
