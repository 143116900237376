import { AlertController } from '@ionic/angular';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { Injectable } from '@angular/core';
import { Observable, Subscription, timer, BehaviorSubject } from 'rxjs';
import { switchMap, first, map } from 'rxjs/operators';
import { io } from 'socket.io-client';
import { Shuttle } from 'src/app/models/shuttle';
import { environment } from 'src/environments/environment';
import { ShuttleService } from '../shuttle/shuttle.service';
import { HelperUtilitiesService } from '../_core/helper-utilities/helper-utilities.service';
import { NotificationsService } from '../_core/notifications/notifications.service';
import * as moment from 'moment';
import { SelectOption } from 'src/app/models/_core/select-option';
import { Route } from 'src/app/models/route';
import { RouteShuttles } from 'src/app/models/route-shuttles';
import { RouteStop } from 'src/app/models/route-stop';
import { ShuttlePosition } from 'src/app/models/shuttle-position';
import { StatusIndex } from 'src/app/models/status-index';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  env = environment;
  shuttles: Shuttle[] = [];
  routes: Route[] = [];
  // routeMap: { [id: string]: Route } = {};
  startStopOptions: BehaviorSubject<SelectOption[]> = new BehaviorSubject([]);
  endStopOptions: BehaviorSubject<SelectOption[]> = new BehaviorSubject([]);
  startStops: BehaviorSubject<RouteStop[]> = new BehaviorSubject([]);
  startStop: BehaviorSubject<RouteStop> = new BehaviorSubject(null);
  endRoutes: BehaviorSubject<Route[]> = new BehaviorSubject([]);
  endStops: BehaviorSubject<Route[]> = new BehaviorSubject([]);
  endStop: RouteStop = null;
  socket: any;
  routeShuttlesMap: { [routeId: string]: ShuttlePosition[] } = {};
  routeShuttlesMapSubject: BehaviorSubject<{ [routeId: string]: ShuttlePosition[] }> = new BehaviorSubject({});
  lastHeartbeat: BehaviorSubject<string> = new BehaviorSubject('');
  lastHeartbeatElapsed: BehaviorSubject<string> = new BehaviorSubject('');
  heartbeatInterval = null;
  statusIndex: StatusIndex = {};
  subscriptions: Subscription[] = [];
  selectedRoute: Route;
  loadStatus: BehaviorSubject<'loaded' | 'not-loaded'> = new BehaviorSubject('not-loaded');
  expireTokenCnt = 0;
  staleDataWarningPresent = false;
  noShuttles = true;
  tokenCheckTime = '';
  debugLog: BehaviorSubject<string[]> = new BehaviorSubject([]);

  constructor(
    public shuttleService: ShuttleService,
    private helpers: HelperUtilitiesService,
    private notifications: NotificationsService,
    private authService: AuthService,
    private alertCtrl: AlertController
  ) { }

  async init(): Promise<boolean> {
    // console.log('trackingService: init => loading data');
    await this.loadRoutes();
    await this.parseStartStops();
    await this.parseEndStops();
    await this.loadStartStopOptions();
    this.startSocket();
    this.startHeartbeatCheck();
    return Promise.resolve(true);
  }

  async restart(): Promise<boolean> {
    await this.destroy();
    await this.init();
    return Promise.resolve(true);
  }

  async destroy(): Promise<boolean> {
    this.stopHeartbeatCheck();
    for (let s of this.subscriptions) {
      s.unsubscribe();
      s = null;
    }
    this.subscriptions = [];
    this.unsubscribeRouteShuttles();
    return Promise.resolve(true);
  }

  async loadRoutes(): Promise<boolean> {
    try {
      const routes = await this.shuttleService.getAllRoutes().toPromise();
      console.log('***** loadRoutes()', routes);
      this.routes = routes;
      this.endRoutes.next(this.routes);
      // if (Array.isArray(this.routes)) {
      //   for (const r of this.routes) {
      //     this.routeMap[r.id] = r;
      //   }
      // }
      return Promise.resolve(true);
    } catch (err) {
      throw err;
    }
  }

  startHeartbeatCheck() {
    this.lastHeartbeatElapsed.next(this.helpers.convertMinutesToUserFriendlyTime(0));
    this.heartbeatInterval = setInterval(() => {
      this.lastHeartbeatElapsed.next(this.getElapsedTime(this.lastHeartbeat.getValue(), 'M/D/YYYY h:mm a'));
    }, 60000);
  }

  stopHeartbeatCheck() {
    clearInterval(this.heartbeatInterval);
    this.heartbeatInterval = null;
  }

  getElapsedTime(dateTime: string, dateTimeFormat = 'MM/DD/YYYY hh:mm a'): string {
    const dateTimeMoment = moment(dateTime, dateTimeFormat);
    let elapsedTime = '';
    if (dateTimeMoment.isValid()) {
      const nowMoment = moment();
      const minutesElapsed = nowMoment.diff(dateTimeMoment, 'minute');
      // console.log('minutesElapsed: ' + minutesElapsed);
      if (minutesElapsed > 0) {
        this.presentStaleDataWarning();
      }
      elapsedTime = this.helpers.convertMinutesToUserFriendlyTime(minutesElapsed);
    }
    return elapsedTime;
  }

  async presentStaleDataWarning() {
    this.staleDataWarningPresent = true;
  }

  parseStartStops() {
    if (this.routes && this.routes.length > 0) {
      const startStops = [];
      for (const r of this.routes) {
        // console.log('parseStartStops: route.startStop:', r);
        const existingStartStop = startStops.find(ss => ss.id === r.startStop.id);
        if (!existingStartStop) {
          startStops.push(r.startStop);
        }
      }
      startStops.sort((a, b) => this.helpers.sortByString(a, b, 'name'));
      this.startStops.next(startStops);
    }
  }

  parseEndStops() {
    if (this.routes && this.routes.length > 0) {
      const endStops = [];
      for (const r of this.routes) {
        const existingStop = endStops.find(es => es.id === r.endStop.id);
        if (!existingStop) {
          endStops.push(r.endStop);
        }
      }
      endStops.sort((a, b) => this.helpers.sortByString(a, b, 'name'));
      this.endStops.next(endStops);
    }
  }

  loadStartStopOptions() {
    const startOptions = [];
    for (const rte of this.routes) {
      const existingOpt = startOptions.find(s => s.value === rte.startStop.id);
      if (existingOpt === undefined) {
        // console.log('Adding start stop option', startOptions, existingOpt, rte.startStop);
        startOptions.push({ label: rte.startStop.name, value: rte.startStop.id});
      }
    }
    startOptions.sort((a, b) => this.helpers.sortByString(a, b, 'label'));
    this.startStopOptions.next(startOptions);
  }

  loadEndStopOptions(startStopId: string) {
    const endOptions = [];
    const validRoutes = this.routes.filter(r => r.startStop.id === startStopId && !r.isMultiStop);
    // console.log('loadEndStopOptions: validRoutes: ', validRoutes);
    for (const rte of validRoutes) {
      if (endOptions.find(s => s.value === rte.endStopId) === undefined) {
        endOptions.push({ label: rte.endStop.name, value: rte.endStop.id});
      }
    }
    endOptions.sort((a, b) => this.helpers.sortByString(a, b, 'label'));
    this.endStopOptions.next(endOptions);
  }

  urlNormalizeStopName(stopName: string) {
    return stopName.replace(/\ /gi, '-').trim().toLowerCase();
  }

  async setStartLocation(stopOption: SelectOption, deepLinkPath: string, deepLinkSuffix: string): Promise<boolean> {
    const startStop = this.startStop.getValue();
    if (!startStop || startStop.id !== stopOption.value) {
      // console.log('trackingService: setStartLocation: suffix => ' + deepLinkSuffix);
      this.helpers.setAddressBarUrl(deepLinkPath + stopOption.value + deepLinkSuffix);
      // this.startStop.next(stopOption);
      const endRoutes = [];
      for (const r of this.routes) {
        if (r.startStop.id === stopOption.value) {
          this.getShuttlesForRoute(r);
          r.startStopId = r.startStop.id;
          r.startStopName = r.startStop.name;
          r.endStopId = r.endStop.id;
          r.endStopName = r.endStop.name;
          endRoutes.push(r);
          if (r.routeShuttles && r.routeShuttles.length > 0) {
            this.noShuttles = false;
          }
        }
      }
      // endRoutes = this.removeDuplicateRoutes(endRoutes);
      this.endRoutes.next(endRoutes);
    }
    this.notifications.stopLoading();
    return Promise.resolve(true);
  }

  removeDuplicateRoutes(routes: Route[]) {
    const uniqueRoutes: Route[] = [];
    for (const rte of routes) {
      const hasMatch = uniqueRoutes.find(ur => ur.endStopName === rte.endStopName);
      if (hasMatch === undefined) {
        uniqueRoutes.push(rte);
      }
    }
    return uniqueRoutes;
  }

  async setEndLocationUrl(startStop: RouteStop, endStop: RouteStop, deepLinkPath: string, deepLinkSuffix: string = '') {
    console.log('setEndLocationUrl', startStop, endStop);
    this.helpers.setAddressBarUrl(deepLinkPath + startStop.id + '/to/' + endStop.id);
  }

  getShuttlesForRoute(endRoute: Route) {
    const routeShuttles = this.routeShuttlesMap[endRoute.id];
    if (routeShuttles) {
      for (const rs of routeShuttles) {
        const localArrivalMoment = moment().add(rs.timeToDestination.minutes, 'minutes');
        // const localArrivalMoment = this.helpers.convertUtcToLocal(rs.timeToDestination.timestamp, 'M/D/YYYY h:mm a')
        //   .add(rs.timeToDestination.seconds, 'seconds');
        rs.timeToDestination.arrivalTime = localArrivalMoment.format('h:mm a');
        rs.timeToDestination.arrivalTimeHour = localArrivalMoment.format('h');
        rs.timeToDestination.arrivalTimeMinutes = localArrivalMoment.format('mm');
        rs.timeToDestination.arrivalTimeMeridian = localArrivalMoment.format('a');
        rs.timeToDestination.arrivalTimeMilitary = localArrivalMoment.format('HH:mm:ss');
        console.log('getShuttlesForRoute: Compare arrival times: ',
          rs.timeToDestination.timestamp + ' => ' + localArrivalMoment.format('M/D/YYYY h:mm a'));
      }
      endRoute.routeShuttles = routeShuttles;
    }
  }

  // setShuttleStatus(shuttle: RouteShuttle): ShuttleStatus {
  //   const startStop = this.startStop.getValue();
  //   if (shuttle.arrived && shuttle.zone === startStop.id) {
  //     shuttle.timeToDestination = 0;
  //     return 'arrived';
  //   } else if (shuttle.leaving) {
  //     return 'leaving';
  //   } else {
  //     const seconds = shuttle.timeToDestSeconds;
  //     if (seconds > 90) {
  //       return 'traveling';
  //     } else {
  //       return 'arriving';
  //     }
  //   }
  // }

  startSocket(): void {
    if (this.subscriptions.length === 0) {
      this.subscriptions.push(
        this.connectSocket()
          .pipe(switchMap(() => this.subscribeRouteShuttles()))
          .subscribe(
            async (data) => {
              // Use Token Check Throttling to limit number of times API is called
              // Validate token, capture current time
              const nowMoment = moment();

              // console.log('startSocket => subscribe => ', data);

              // Set checkToken flag to true if token never checked or token last checked greater than 5 minutes ago
              const tokenCheckAge = nowMoment.diff(moment(this.tokenCheckTime, 'MM/DD/YYYY HH:mm:ss'), 'minutes');
              const checkToken = this.tokenCheckTime === '' || tokenCheckAge > 5;

              // console.log('**** Testing timestamp', tokenCheckAge, tokenCheckAge > 15);

              try {
                // Set flag for valid token
                let isTokenValid = false;

                if (checkToken) {
                  // Check with API to see if token is valid
                  const tokenStatus = await this.authService.validateToken().toPromise();
                  isTokenValid = tokenStatus.tokenValid;
                  // Capture token check time
                  this.tokenCheckTime = moment().format('MM/DD/YYYY HH:mm:ss');
                } else {
                  // Token check less than 5 minutes old, assume valid
                  isTokenValid = true;
                }

                // If token is valid, handle routes
                if (isTokenValid) {
                  this.lastHeartbeat.next(moment().format('M/D/YYYY h:mm a'));
                  this.routeShuttlesMap = data;
                  this.routeShuttlesMapSubject.next(this.routeShuttlesMap);
                  const endRoutes = this.endRoutes.getValue();
                  for (const er of endRoutes) {
                    // console.log('Tracking: Handle endRoute', er);
                    this.getShuttlesForRoute(er);
                    if (er.routeShuttles && er.routeShuttles.length > 0) {
                      this.noShuttles = false;
                    }
                  }
                  this.staleDataWarningPresent = false;
                } else {
                  // Bad or old token detected, log out
                  this.staleDataWarningPresent = true;
                  this.authService.logout(false, true);
                }
              } catch (err) {
                this.notifications.handleError(err, 'startSocket: connectSocket => payload');
              }
            },
            (error) => {
              this.notifications.showError(`Can not connect to shuttle websocket server`);
            }
          )
      );
    }
  }

  // onRouteChange(route: Route): void {
  //   this.updateShuttlesLocation();
  // }

  // updateShuttlesLocation(): void {
  //   if (this.selectedRoute) {
  //     let shuttles = this.routeShuttlesMap[this.selectedRoute.id];
  //     if (shuttles) {
  //       const minTime = Math.min.apply(
  //         Math,
  //         shuttles.map((item) => item.timeToDestination)
  //       );
  //       shuttles = shuttles.filter((o) => o.timeToDestination === minTime && !o.leaving);
  //       // console.log('home.updateShuttlesLocation (1) => shuttles', shuttles);
  //     }
  //   } else {
  //     Object.values(this.routeShuttlesMap).forEach((shuttles) => {
  //       const minTime = Math.min.apply(
  //         Math,
  //         shuttles.map((item) => item.timeToDestination)
  //       );
  //       shuttles = shuttles.filter((o) => o.timeToDestination === minTime && !o.leaving);
  //       // console.log('home.updateShuttlesLocation (2) => shuttles', shuttles);
  //     });
  //   }
  // }


  connectSocket(): Observable<any> {
    return new Observable((observer) => {
      if (!this.socket) {
        this.socket = io(this.env.apiUrl + '/routeShuttles',
          { forceNew: true, path: this.env.socketPath, transports: ['websocket'] });
        // Use this when running local
      }
      this.socket.on('connect_error', (error) => {
        console.error('connection error detected');
        this.presentStaleDataWarning();
        observer.error(error);
      });
      this.socket.on('reconnecting', (timeout) => {
        if (timeout === 3) {
          this.socket.close();
          this.socket = null;
          observer.error(`reconnecting failed`);
        }
      });
      this.socket.on('connect', () => {
        console.log(`connected`);
        // this.restart();
        observer.next();
        observer.complete();
      });
    });
  }

  subscribeRouteShuttles(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('error', (error) => {
        console.error(`ws error ${error}`);
      });
      this.socket.on('data', (data) => {
        const routeShuttlesMap: { [routeId: string]: ShuttlePosition[] } = {};
        // let shuttleIdArray: string[] = [];
        if (data) {
          const routes = data as RouteShuttles[];
          for (const r of routes) {
            if (r) {
              if (routeShuttlesMap[r.routeId] === undefined) {
                routeShuttlesMap[r.routeId] = [];
              }
              routeShuttlesMap[r.routeId] = r.shuttles;
            } else {
              console.warn('Route is null:', r);
            }
          };
        }

        // shuttleIdArray = Array.from(new Set(shuttleIdArray));

        // console.log('shuttleIdArray', shuttleIdArray);
        // if (shuttleIdArray.length > 0) {
        //   this.shuttleService.getShuttleNames(shuttleIdArray).pipe(first()).subscribe(names => {
        //   for (const shuttles of Object.values(routeShuttlesMap)) {
        //     for (const sh of shuttles) {
        //       const name = names[sh.id];
        //       sh.name = (name) ? name : 'Unknown';
        //       sh.label = (name) ? 'Shuttle ' + name : 'Unknown';
        //       this.shuttleService.formatTime(sh);

        //     }
        //   }
        //     // Object.values(routeShuttlesMap).forEach((shuttles) => {
        //     //   shuttles.forEach((shuttle) => {
        //     //   });
        //     // });
        //     observer.next(routeShuttlesMap);
        //   });
        // } else {
        //   observer.next(routeShuttlesMap);
        // }
        observer.next(routeShuttlesMap);
      });
    });
  }

  getDestinationId(destination) {
    return (destination.indexOf(' ') > -1) ? destination.split(' ')[0] : destination;
  }

  unsubscribeRouteShuttles() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  }

  writeToDebugLog(text: string, clearLog = false) {
    let log = this.debugLog.getValue();
    if (clearLog) {
      log = [];
    }
    log.push(text);
    this.debugLog.next(log);
  }

  // load route shuttle location every 1 second
  // getAssignedShuttlesByRoute(routeId: string): Observable<any> {
  //   return timer(200, 1000).pipe(
  //     switchMap(() =>
  //       this.shuttleService.getRouteShuttles(routeId).pipe(
  //         map((shuttles) => {
  //           if (shuttles) {
  //             return shuttles.filter((shuttle: RouteShuttle) => shuttle.lat && shuttle.lng);
  //           } else {
  //             return [];
  //           }
  //         })
  //       )
  //     )
  //   );
  // }

}
