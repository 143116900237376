import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { environment } from 'src/environments/environment';
import { HelperUtilitiesService } from '../../../services/_core/helper-utilities/helper-utilities.service';
import { ModalController, NavParams, NavController } from '@ionic/angular';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Platform } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { VerlockerData } from '../../../models/_core/verlocker-data';
import { BhAnalyticsService } from '../../../services/_core/bhanalytics/bhanalytics.service';

/**
 * ID: bh-verlocker-page
 * Name: BH Verlocker Page
 * Description: Verlocker modal page that appears when the user is required to update this app.
 * Version: 2
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2021-07-13 - MW - v2: Implemented new device-specific instructions
 */
@Component({
  selector: 'app-verlocker',
  templateUrl: './verlocker.page.html',
  styleUrls: ['./verlocker.page.scss'],
})
export class VerlockerPage implements OnInit {
  env = environment;
  verlockerData: VerlockerData;
  navParams = new NavParams();
  currentView: 'prompt' | 'downloading' = 'prompt';
  osPlatform: 'android' | 'ios' | 'web';
  updateAttempted = false;

  constructor(
    private platform: Platform,
    private inAppBrowser: InAppBrowser,
    private modalController: ModalController,
    private analytics: BhAnalyticsService,
    public helpers: HelperUtilitiesService,
    private navService: NavigationService
  ) {
  }

  ngOnInit() {
  }

  ionViewDidEnter() {
    // console.log('Verlocker page data:', this.verlockerData);
  }

  update() {
    this.currentView = 'downloading';
    if (this.platform.is('android')) {
      this.analytics.verlockerInstall();
      this.osPlatform = 'android';
      this.appUpdate(this.verlockerData.android_install_link);
    } else if (this.platform.is('ios')) {
      this.analytics.verlockerInstall();
      const url = decodeURIComponent(this.verlockerData.ios_install_link + '');
      if (this.platform.is('cordova') || this.platform.is('capacitor')) {
        this.osPlatform = 'ios';
        this.appUpdate(url);
      } else {
        // running on iOS Safari browser
        this.analytics.clickEvent('verlocker: ios-web-browser', '');
        this.osPlatform = 'web';
        this.webUpdate();
      }
    } else {
      console.log('Web update');
      this.osPlatform = 'web';
      this.webUpdate();
    }
  }

  webUpdate() {
    this.startUpdateAttempt();
    setTimeout(() => {
      // console.log('Upgrade App-Not a valid platform');
      this.analytics.clickEvent('verlocker: web-refresh', '');
      const url = window.location.protocol + '//' + window.location.host + '/';
      window.location.href = url;
    }, 1000);
  }

  appUpdate(url) {
    this.startUpdateAttempt();
    setTimeout(() => {
      if (this.osPlatform === 'ios') {
        this.analytics.clickEvent('verlocker: ios-update', '');
        this.inAppBrowser.create(url, '_system');
      } else {
        this.analytics.clickEvent('verlocker: android-update', '');
        window.open(url, '_system');
      }
    }, 1000);
  }

  cancel() {
    this.analytics.clickEvent('verlocker: cancel', '');
    this.modalController.dismiss();
  }

  getHelp() {
    this.modalController.dismiss();
    this.navService.navigateForward('help');
  }

  startUpdateAttempt() {
    setTimeout(() => {
      this.updateAttempted = true;
    }, 7000);
  }

}
