import { UserDevice } from './../../models/_core/user-device';
import { UserDeviceService } from './../_core/user-device/user-device.service';
import { User } from './../../models/user';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { Subscription } from 'rxjs';
import { NavController, Platform } from '@ionic/angular';
import { AuthService } from './../_core/auth/auth.service';
import { NavPage } from './../../models/_core/nav-page';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  // Define your pages for this app here
  navPages: NavPage[] = [
    {
      name: 'Home',
      navPath: '/tabs/home',
      tabId: 'home',
      inactiveIonIcon: 'home-outline',
      activeIonIcon: 'home',
      roles: ['USER', 'ADMIN', 'SYS_ADMIN'],
      isAccessible: false
    },
    {
      name: 'Driver',
      navPath: '/route-select',
      tabId: 'route-select',
      inactiveIonIcon: 'bus-outline',
      activeIonIcon: 'bus',
      roles: ['ADMIN', 'SYS_ADMIN'],
      isAccessible: false
    },
    {
      name: 'Manage',
      navPath: '/manage',
      tabId: 'manage',
      inactiveIonIcon: 'cog-outline',
      activeIonIcon: 'cog',
      roles: ['SYS_ADMIN'],
      isAccessible: false
    }
  ];

  driverPages: NavPage[] = [
    {
      name: 'Starting Route',
      navPath: '/tabs/manage/route-select',
      tabId: 'route-select',
      inactiveIonIcon: 'git-commit-outline',
      activeIonIcon: 'git-commit',
      roles: ['ADMIN', 'SYS_ADMIN'],
      isAccessible: false
    },
    {
      name: 'Shuttles',
      navPath: '/tabs/manage/shuttles',
      tabId: 'shuttles',
      inactiveIonIcon: 'bus-outline',
      activeIonIcon: 'bus',
      roles: ['ADMIN', 'SYS_ADMIN'],
      isAccessible: false
    },
  ];
  showNotifications = false;
  subscriptions: Subscription[] = [];
  accessiblePages = 0;
  userDevice: UserDevice;

  constructor(
    private navCtrl: NavController,
    private platform: Platform,
  ) {
  }

  checkPrivileges(authUser: User) {
    this.checkPages(this.navPages, authUser);
    this.accessiblePages = this.navPages.filter(p => p.isAccessible).length;
    this.checkPages(this.driverPages, authUser);
  }

  checkPages(navPages: NavPage[], authUser: User){
    navPages.forEach(page => {
      if (page.roles && page.roles.length > 0) {
        if (authUser) {
          // User logged in, check roles
          const matches = page.roles.filter(r => (r && authUser && authUser.role && r.toLowerCase() === authUser.role.toLowerCase()));
          page.isAccessible = (matches.length > 0);
        } else {
          // User not logged in
          page.isAccessible = false;
        }
      } else {
        // Page unprotected
        page.isAccessible = true;
      }
    });
  }

  navigateHome() {
    this.navigateBack('/tabs/home');
  }

  navigateForward(pathUrl, navOptions: NavigationOptions = undefined) {
    if(this.platform.is('mobile') || this.platform.is('mobileweb')) {
      this.navCtrl.navigateForward(pathUrl, navOptions);
    } else {
      this.navCtrl.navigateRoot(pathUrl, navOptions);
    }
  }

  navigateBack(pathUrl, navOptions: NavigationOptions = undefined) {
    if(this.platform.is('mobile') || this.platform.is('mobileweb')) {
      this.navCtrl.navigateBack(pathUrl, navOptions);
    } else {
      this.navCtrl.navigateRoot(pathUrl, navOptions);
    }
  }

  navigateRoot(pathUrl, navOptions: NavigationOptions = undefined) {
    this.navCtrl.navigateRoot(pathUrl, navOptions);
  }
}
