import { HttpClient } from '@angular/common/http';
import { NotificationsService } from '../notifications/notifications.service';
import { Injectable } from '@angular/core';
import { Platform, AlertController } from '@ionic/angular';
import { Subscription, BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Network } from '@capacitor/network';

/**
 * ID: bh-network-service
 * Name: BH Network Service
 * Description: Service used for monitoring network state (online, offline, wifi, cellular)
 * Version: 1
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 */
@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  env = environment;
  isOnline: BehaviorSubject<boolean> = new BehaviorSubject(true);
  isConnected: BehaviorSubject<boolean> = new BehaviorSubject(true);
  connectSub: Subscription = null;
  disconnectSub: Subscription = null;
  healthCheckInterval = null;
  private alert: any = null;

  constructor(
    public platform: Platform,
    public alertController: AlertController,
    public notificationsService: NotificationsService,
    private http: HttpClient,
  ) {
    console.log('network-service: started');

    Network.addListener('networkStatusChange', status => {
      console.log('Network status changed', status);
      this.isOnline.next(status.connected);
    });

    this.monitorHealthCheck();

    // this.disconnectSub = .onDisconnect().subscribe(() => {
    //   console.log('network was disconnected :-(');
    //   this.isOnline.next(false);
    // });

    // this.connectSub = this.network.onConnect().subscribe(() => {
    //   console.log('network connected!');
    //   // We just got a connection but we need to wait briefly
    //   // before we determine the connection type. Might need to wait.
    //   // prior to doing any api requests as well.
    //   this.isOnline.next(true);
    //   setTimeout(() => {
    //     if (this.network.type) {
    //       console.log('we\'re connected!', this.network.type);
    //     }
    //   }, 3000);
    // });

  }

  async getNetworkType(): Promise<string> {
    const status = await Network.getStatus();
    return Promise.resolve(status.connectionType.toString());
  }

  getNetworkStatus(): boolean {
    return this.isOnline.getValue() && this.isOnline.getValue();
  }

  // async presentNetworkOfflineAlert() {
  //   if (!this.alert) {
  //     this.alert = await this.alertController.create({
  //       header: 'Your device disconnected from the network.',
  //       message: 'Attempting to reconnect...',
  //       backdropDismiss: false,
  //     });
  //     await this.alert.present();
  //   }
  // }

  // async dismissNetworkOfflineAlert() {
  //   if (this.alert) {
  //     await this.alert.dismiss();
  //     this.alert = null;
  //     this.notificationsService.showToast('Your device reconnected to the network.');
  //   }
  // }

  async monitorHealthCheck() {
    try {
      const initRes = await this.checkApiHealth().toPromise();
      if (initRes && initRes.name && initRes.version) {
        this.isConnected.next(true);
      } else {
        this.isConnected.next(false);
      }
    } catch (err) {
      // this.notificationsService.showError('Unable to connect to server.');
      this.isConnected.next(false);
    }

    if (this.healthCheckInterval) {
      clearInterval(this.healthCheckInterval);
      this.healthCheckInterval = null;
    }

    this.healthCheckInterval = setInterval(async () => {
      try {
        const res = await this.checkApiHealth().toPromise();
        if (res && res.name && res.version) {
          this.isConnected.next(true);
        } else {
          this.isConnected.next(false);
        }
      } catch (err) {
        // Catch error
        this.isConnected.next(false);
      }
    }, 60000);

  }

  checkApiHealth(): Observable<any> {
    const url = this.env.apiUrl + '/healthcheck';
    return this.http.get(url).pipe(
      map((data: any) => data),
      catchError(err => of(err))
    );
  }


}
