import { NotificationsService } from './../../../services/_core/notifications/notifications.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BhAnalyticsService } from 'src/app/services/_core/bhanalytics/bhanalytics.service';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';

/**
 * ID: bh-feedback-page
 * Name: BH Feedback Page
 * Description: Simple NPS rating tool that sends data to our bh-analytics engine.  Captures user satisfaction.
 * Version: 1
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 */
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.page.html',
  styleUrls: ['./feedback.page.scss'],
})
export class FeedbackPage implements OnInit {
  env = environment;
  selectedRating = null;
  raterValue = 0;
  q2Value = '';
  q3Value = '';
  npsRating = null;
  placeholderText = '';
  currentView: 'form' | 'confirmation' = 'form';
  showFullForm = false;

  constructor(
    private modalCtrl: ModalController,
    private analytics: BhAnalyticsService,
    private notifications: NotificationsService
  ) { }

  ngOnInit() {
  }


  selectRater(val) {
    this.raterValue = val;
    this.showFullForm = true;
  }

  setRating(rating) {
    this.analytics.clickEvent('feedback: selected-rating', rating);
    this.npsRating = rating;
  }

  validateForm() {
    return this.raterValue && this.q2Value && this.q3Value && this.npsRating;
  }

  async submit() {
    if (this.validateForm()) {
      this.notifications.startLoading('Submitting');
      const q2Value = this.q2Value.substr(0, 120);
      const q3Value = this.q3Value.substr(0, 120);
      const details = this.raterValue + '|' + this.q2Value + '|' + this.q3Value + '|' + this.npsRating;
      await this.analytics.customEvent('ct-feedback', this.raterValue, details);
      this.currentView = 'confirmation';
      setTimeout(() => {
        this.dismiss(true);
      }, 3000);
      this.notifications.stopLoading();
    }
  }

  dismiss(isCompleted = false) {
    this.analytics.clickEvent('feedback: dismiss', '');
    this.modalCtrl.dismiss({ isCompleted });
  }

}
